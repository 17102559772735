<template>
    <div id="fencelist">
        <div class="crumbs">车辆管理 / 围栏列表</div>
        <div class="center">
            <div class="table">
                <div class="center_top">
                    <span>
                        <el-form :inline="true" class="demo-form-inline">
                            <el-form-item>
                                <!-- <el-button type="primary" @click="NewEnclosureShow = true" size="medium">新增围栏</el-button> -->
                            </el-form-item>
                        </el-form>
                    </span>
                </div>
                <el-table :data="tableData" style="width: 100%"
                    :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                    <el-table-column prop="fence_id" label="编号" width="50" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="fence_name" width="200" label="围栏名称" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" width="200" :align="'center'">
                    </el-table-column>
                    <el-table-column prop="create_time" label="生效时间" width="200" :align="'center'">
                        <template slot-scope="scope">
                            <div>
                                {{ scope.row.effective_time[0] }}至{{ scope.row.effective_time[1] }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" :align="'center'">
                        <template slot-scope="scope">
                            <el-button type="text" @click="SeeAdminCarFn(scope.row.vihicle_list)">查看管理车辆</el-button>
                            <el-button type="text" @click="EditAdminCarShowFn(scope.row.fence_id)">管理车辆</el-button>
                            <el-button type="text" @click="editShowFn(scope.row)">编辑</el-button>
                            <el-button type="text" @click="deleteFenceFn(scope.row.fence_id)">删除</el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </div>
            <div>
                <span style="float: right; margin-top: 20px;margin-right: 20px;">
                    <el-pagination :current-page="page" :page-sizes="[10, 20, 50, 100]" :page-size="limit"
                        layout="total, sizes, prev, pager, next, jumper" :total="tableData.length" background>
                    </el-pagination>
                </span>
            </div>
        </div>

        <el-dialog title="围栏管理车辆" :visible.sync="SeeAdminCarShow" width="30%">
            <el-table :data="vihicle_list" :header-cell-style="{ background: '#F5F7FA', 'text-align': 'center' }">
                <el-table-column prop="vihicle_nickname" label="车辆类型" :align="'center'"></el-table-column>
                <el-table-column prop="vihicle_number" label="车牌号" :align="'center'"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="编辑围栏管理车辆" :visible.sync="EditAdminCarShow" width="30%">
            <el-form label-width="100px" :model="EnclosureData">
                <el-form-item label="操作类型:">
                    <el-select v-model="type" placeholder="请选择入围/出围" @change="selectchange">
                        <el-option label="入围" :value="1"></el-option>
                        <el-option label="出围" :value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="车辆:">
                    <el-select v-model="carid" placeholder="请选择车牌号">
                        <el-option v-for="item, index in VihicleList" :label="item.vihicle_number" :value="item.vihicle_id"
                            :key="index"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="EditAdminCarShow = false">取 消</el-button>
                <el-button type="primary" @click="EditAdminCarFn">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="编辑围栏" :visible.sync="editShow" width="30%">
            <el-form label-width="100px" :model="editData">
                <el-form-item label="围栏名称:">
                    <el-input v-model="editData.fence_name"></el-input>
                </el-form-item>
                <el-form-item label="生效起始时间:">
                    <el-time-select v-model="startTime" :picker-options="{
                        start: '00:00',
                        step: '01:00',
                        end: '23:00'
                    }" placeholder="选择时间">
                    </el-time-select>
                </el-form-item>
                <el-form-item label="生效结束时间:">
                    <el-time-select v-model="endTime" :picker-options="{
                        start: '00:00',
                        step: '01:00',
                        end: '23:00'
                    }" placeholder="选择时间">
                    </el-time-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="NewEnclosureShow = false">取 消</el-button>
                <el-button type="primary" @click="NewEnclosureFn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { fenceList, operateFence, deleteFence, fenceVihicle, getVihicleList } from "../request/api"
export default {
    name: 'DemoFencelist',
    data() {
        return {
            tableData: [],
            counts: 0,//总数
            page: 1,//当前页
            limit: 100,//一页展示几条
            NewEnclosureShow: false,//新增围栏是否显示
            EnclosureData: {
                fence_name: "",
                longitudeDegree: null,//经度的度
                longitudeBranch: null,//经度的分
                longitudeSecond: null,//经度的秒
                latitudeDegree: null,//纬度的度
                latitudeBranch: null,//纬度的分
                latitudeSecond: null,//纬度的秒
                radius: ""
            },
            SeeAdminCarShow: false,//查看入围车辆是否显示
            vihicle_list: [],//查看入围车辆的数据
            EditAdminCarShow: false,//编辑入围车辆是否显示
            VihicleList: [],
            type: 1,
            carid: null,
            fence_id: null,
            editShow: false,
            editData: {

            },
            startTime: '',
            endTime: ''
        };
    },

    async mounted() {
        this.getList()
        let res = await getVihicleList({
            is_infence: 2
        })
        this.VihicleList = res.data.list
    },
    methods: {
        onSubmit() {
            this.getList()
        },
        async getList() {
            let res = await fenceList()
            this.tableData = res.data.list
        },
        //度分秒转经纬度
        degreeMinuteSecondConvertCoordinates(degree, minute, second) {
            return (Number(degree) + Number(minute / 60) + Number(second / 60 / 60)).toFixed(8)
        },
        //新增围栏
        async NewEnclosureFn() {
            let res = await operateFence({
                fence_name: this.editData.fence_name,
                effective_time: this.startTime + "-" + this.endTime,
                fence_id: this.editData.fence_id,
            })
            if (res.code == 1) {
                this.editShow = false
                this.$message({
                    message: '操作成功',
                    type: 'success'
                })
                this.getList()
            }
            console.log(res);
        },
        SeeAdminCarFn(data) {
            this.SeeAdminCarShow = true
            this.vihicle_list = data
        },
        deleteFenceFn(id) {
            this.$confirm('此操作将会删除该围栏, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let res = await deleteFence({ fence_id: id })
                this.getList()
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        async EditAdminCarShowFn(id) {
            this.EditAdminCarShow = true
            this.fence_id = id
            this.type = 1
            let res = await getVihicleList({
                is_infence: 2
            })
            this.VihicleList = res.data.list
            this.carid = null
        },
        async EditAdminCarFn() {
            let res = await fenceVihicle({
                fence_id: this.fence_id,
                vihicle_id: this.carid,
                type: this.type
            })
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.EditAdminCarShow = false
            this.getList()
            this.type = 1
            let res1 = await getVihicleList({
                is_infence: 2
            })
            this.VihicleList = res1.data.list
            this.carid = null
        },
        async selectchange(val) {
            if (val == 2) {
                let a = this.tableData.filter((item) => {
                    return item.fence_id == this.fence_id
                })
                this.VihicleList = a[0].vihicle_list
                this.carid = null
            } else {
                let res = await getVihicleList({
                    is_infence: 2
                })
                this.VihicleList = res.data.list
                this.carid = null
            }
        },
        editShowFn(row) {
            this.editShow = true
            this.editData = row
        }

    },
};
</script>

<style lang="less" scoped>
#fencelist {
    width: 100%;
    height: 100vh;

}

.crumbs {
    padding: 5px 20px;
    background-color: rgb(240, 240, 240);
}

.center {
    width: 100vw;
    height: calc(100% - 31px);
    // background-color: #000;
    margin: auto;




    .center_top {
        padding-top: 10px;
        padding-bottom: 13px;
        height: 36px;
        display: flex;
        justify-content: center;

        ::v-deep .el-input__inner {
            width: 190px;
            height: 32px;
        }
    }

    .table {
        height: calc(100vh - 100px);
        overflow: auto;
        padding: 0 10px;
    }
}
</style>